/* // X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap */
.App {
    text-align: center;
    box-sizing: border-box;
}
@import url(https://fonts.googleapis.com/css?family=Noto+Sans);
body {
    background-color: hsl(205, 38%, 89%);
    max-width: 768px;
    margin: 0 auto;
    /* padding: 0 5%; */
    font-size: 100%;
    font-family: "Noto Sans", sans-serif;
    color: black;
}

h2 {
    margin: 0;
    color: hsl(206, 21%, 24%);
}

.container {
    margin: 1rem 0 1rem 1rem;
    border-left: .2rem solid white;
}
/* .container {
    margin: 1rem;  
} */

.card {
    border-radius: 1rem;
    background-color: white;
    width: 75%;
    padding: 1.5rem;
    margin: 2rem auto;
}

img {
    width: 100%;
    /* max-height: 500px; */
    object-fit: cover;
    object-position:center top;
}

.timeline-date {
    width: fit-content;
    /* height: 50px; */
    /* line-height: 50px; */
    font-size: 1.2em;
    position: relative;
    left: -1rem;
    background-color: white;
    padding: 0.5rem 1rem;
    border-radius: 10px;
}

.first-timeline-date{
    height: 20px;
    /* width: 15px; */

    border-radius: 50%;
    /* //width: fit-content; */
    position: relative;
    font-size: initial;
    left: -1.2rem;
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .container {
        margin-left: auto;
        margin-right: auto;
    }
    .card {
        padding: 2rem;
        width: 80%;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}